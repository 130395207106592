export const landingRoutes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/Pages/Ru/index.vue'),
    meta: {
      name: 'Landing',
    },
  },
];
