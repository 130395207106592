const lazyPageComponent = () => import('@/Pages/Ru/CompanyDetails.vue');

export const companyDetailsRoutes = [
  {
    path: '/company-details',
    pathname: '/company-details',
    name: 'company-details',
    component: lazyPageComponent,
    meta: {
      name: 'company-details',
    },
  },
];
