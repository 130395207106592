const lazyTermsAndConditionsPageComponent = () => import('@/Pages/En/TermsAndConditions.vue');

export const termsAndConditionsRoutes = [
  {
    path: '/terms-and-conditions',
    pathname: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: lazyTermsAndConditionsPageComponent,
    meta: {
        name: 'terms-and-conditions',
    },
  },
];
