const lazyPageComponent = () => import('@/Pages/Ru/Payment.vue');

export const paymentRoutes = [
  {
    path: '/payment',
    pathname: '/payment',
    name: 'payment',
    component: lazyPageComponent,
    meta: {
      name: 'payment',
    },
  },
];
