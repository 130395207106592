const lazyForCompaniesPageComponent = () => import('@/Pages/En/ForCompanies.vue');

export const forCompaniesRoutes = [
    {
        path: '/for-companies',
        pathname: '/for-companies',
        name: 'for-companies',
        component: lazyForCompaniesPageComponent,
        meta: {
            name: 'for-companies',
        },
    },
];
