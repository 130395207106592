const lazyPaymentFormPageComponent = () => import('@/Pages/En/PaymentForm.vue');

export const paymentRoutes = [
  {
    path: '/checkout',
    pathname: '/checkout',
    name: 'checkout',
    component: lazyPaymentFormPageComponent,
    meta: {
        name: 'checkout',
        title: 'Getfloorplan | Place Your Order',
    },
  },
];
