const lazyPricingPageComponent = () => import('@/Pages/En/Pricing.vue');

export const pricingRoutes = [
    {
        path: '/pricing',
        pathname: '/pricing',
        name: 'pricing',
        component: lazyPricingPageComponent,
        meta: {
            name: 'pricing',
        },
    },
];
