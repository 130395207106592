const lazyPrivicyPolicyPageComponent = () => import('@/Pages/En/PrivicyPolicy.vue');

export const privicyPolicyRoutes = [
  {
    path: '/privacy-policy',
    pathname: '/privacy-policy',
    name: 'privacy-policy',
    component: lazyPrivicyPolicyPageComponent,
    meta: {
        name: 'privacy-policy',
    },
  },
];
