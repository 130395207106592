const lazyNewsPageComponent = () => import('@/Pages/En/News.vue');

export const newsRoutes = [
    {
        path: '/news',
        pathname: '/news',
        name: 'news',
        component: lazyNewsPageComponent,
        meta: {
            name: 'news',
        },
    },
];
