import { createRouter, createWebHistory } from 'vue-router';

import { landingRoutes } from '@/router/ru/landingRoutes';
import { privicyPolicyRoutes } from '@/router/ru/privicyPolicy';
import { companyDetailsRoutes } from '@/router/ru/companyDetailsRoutes';
import { paymentRoutes } from '@/router/ru/payment';
import { emptyWidgetRoutes, widgetRoutes } from '@/router/ru/widgetRoutes';
import { errorRoutes } from '@/router/ru/errorRoutes';
import { cianRoutes } from '@/router/ru/cian';

const routes = [...landingRoutes, ...privicyPolicyRoutes, ...companyDetailsRoutes, ...paymentRoutes, ...widgetRoutes, ...emptyWidgetRoutes, ...errorRoutes, ...cianRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return { top: 0 };
  },
});

export { router, routes };
