const lazyPublicPlanPageComponent = () => import('@/Pages/PublicPlan/index.vue');

export const publicPlanRoutes = [
  {
    path: '/public_fp/:id',
    name: 'public-plan',
    component: lazyPublicPlanPageComponent,
    meta: {
        name: 'public-plan',
    },
  },
];
