// Global site tag (gtag.js) - Google Analytics
window.dataLayer = window.dataLayer || [];
window.gtag = function () {
    dataLayer.push(arguments);
};

(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-WJLCNPR');

export const triggeringGoogleTagMetric = {
    openFormImage: () => gtag('event', 'OpenFormImage'),
    submitFormImage: () => gtag('event', 'SubmitFormImage'),
    openFormQuestion: () => gtag('event', 'OpenFormQuestion'),
    submitFormQuestion: () => gtag('event', 'SubmitFormQuestion'),
};
