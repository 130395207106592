<template>
  <router-view />
</template>

<script>
export default {
  name: 'Landing',
  props: {
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      keywords:
        '3D планировки, 3D туры, ИИ 3D дизайн, 3D туры за 24 часа, визуализация недвижимости, 3D из 2D плана, планировки жилья, 3D проекты для застройщиков, маркетинг недвижимости, digital агентства, ИИ в недвижимости, реклама недвижимости, услуги для застройщиков, 3D визуализация недвижимости',
    };
  },
  mounted() {
    this.updateMetaTags();

    if (window.location.hostname === 'stage.getfloorplan.com' || window.location.hostname === 'stage.hart-estate.ru') {
      this.removeWebsiteFromSearchIndex();
    }
  },
  methods: {
    removeWebsiteFromSearchIndex() {
      const meta = document.querySelector('meta[name="robots"]');
      if (meta) {
        meta.content = 'noindex, nofollow';
      }
    },
    updateMetaTags() {
      document.querySelectorAll('meta[name="description"], meta[name="keywords"]').forEach((el) => el.remove());

      if (this.lang === 'ru') {
        document.title = 'Hart Estate: 3D планировки и 3D туры за 24 часа с помощью ИИ из 2D плана жилья. ';

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Создаем 3D туры и дизайн проекты для увеличения конверсии рекламы по продаже недвижимости до 30% с помощью ИИ. Высокое качество, стоимость ниже дизайнеров, выполнение за 24 часа. Идеально для застройщиков и digital агентств.';
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = this.keywords;
        document.head.appendChild(metaKeywords);
      } else {
        document.title = 'Creating 2D and 3D floor plans with AI';

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Download a 2D floor plan to get great 3D renderings with furniture and a virtual tour.';
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = '3d floor plan cost, floor plan rendering, create 3d floor plans, 3d floor plan, 3d floor plan small house, home floor plan builder, 3d floorplan maker, convert floorplan, floorplan converter';
        document.head.appendChild(metaKeywords);
      }
    },
  },
};
</script>
