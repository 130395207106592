const lazyPageComponent = () => import('@/Pages/Ru/PrivicyPolicy.vue');

export const privicyPolicyRoutes = [
  {
    path: '/privacy-policy',
    pathname: '/privacy-policy',
    name: 'privacy-policy',
    component: lazyPageComponent,
    meta: {
      name: 'privacy-policy',
    },
  },
];
