const lazyWidgetPageComponent = () => import('@/Pages/Widget/index.vue');

export const widgetRoutes = [
  {
    path: '/widget',
    pathname: '/widget',
    name: 'widget',
    component: lazyWidgetPageComponent,
    meta: {
      name: 'widget',
    },
  },
];

export const emptyWidgetRoutes = [
  {
    path: '/widget-clear',
    pathname: '/widget-clear',
    name: 'emptyWidget',
    component: lazyWidgetPageComponent,
    meta: {
      name: 'emptyWidget',
    },
  },
];
