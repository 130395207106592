const NotFoundPage = () => import('@/Pages/Ru/NotFoundPage.vue');
const ServerError = () => import('@/Pages/Ru/ServerErrorPage.vue');

export const errorRoutes = [
  {
    path: '/500',
    name: 'server-error',
    component: ServerError,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage,
  },
];
