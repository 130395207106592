import { createStore } from 'vuex';

export default createStore({
  state: {
    paymentLink: null,
  },
  mutations: {
    setPaymentLink(state, link) {
      state.paymentLink = link;
    },
  },
});
