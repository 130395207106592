const lazyPageComponent = () => import('@/Pages/Ru/CianPage.vue');

export const cianRoutes = [
  {
    path: '/cian',
    pathname: '/cian',
    name: 'cian',
    component: lazyPageComponent,
    meta: {
      name: 'cian',
    },
  },
];
