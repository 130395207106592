export const landingRoutes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/Pages/En/index.vue'),
    meta: {
        name: 'Landing',
    },
  },
];
