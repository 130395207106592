const lazyForPersonalUsersPageComponent = () => import('@/Pages/En/ForPersonalUsers.vue');

export const forPersonalUsersRoutes = [
    {
        path: '/for-personal-users',
        pathname: '/for-personal-users',
        name: 'for-personal-users',
        component: lazyForPersonalUsersPageComponent,
        meta: {
            name: 'for-personal-users',
        },
    },
];
